<template>
    <v-row v-resize="onResize" no-gutters class="fill-height">
        <v-col
            ref="menu"
            cols="12"
            md="3"
            sm="12"
            lg="3"
            class="border-right d-flex flex-column flex-nowrap"
            :style="`height: ${height}px;`"
        >
            <v-row no-gutters class="border-bottom flex-grow-0">
                <!-- Search -->
                <v-col cols="12" class="pa-2 pb-2">
                    <v-text-field
                        v-model="search"
                        clearable
                        dense
                        filled
                        hide-details="auto"
                        placeholder="Search"
                        prepend-inner-icon="mdi-magnify"
                        rounded
                    />
                </v-col>
            </v-row>
            <v-row no-gutters class="border-bottom flex-grow-0">
                <!-- Sort -->
                <v-col
                    cols="12"
                    class="py-2 px-2  d-flex align-center justify-start"
                >
                    <v-select
                        v-model="state"
                        flat
                        :items="states"
                        hide-details="auto"
                        solo
                    />
                </v-col>
            </v-row>
            <!-- Loader -->
            <div
                v-if="loading"
                class="d-flex align-center justify-center flex-grow-1"
            >
                <v-progress-circular color="primary" indeterminate />
            </div>
            <!-- Quotes list -->
            <v-list v-else class="overflow-y-auto pa-0">
                <template v-if="filteredQuotes.length > 0">
                    <div v-for="(quote, i) in filteredQuotes" :key="quote.id">
                        <v-sheet
                            min-height="100"
                            class="fill-height"
                            color="transparent"
                        >
                            <v-lazy
                                :v-model="quote"
                                :options="{
                                    threshold: 0.5,
                                }"
                                class="fill-height"
                            >
                                <v-list-item
                                    color="primary"
                                    three-line
                                    link
                                    @click="hide(quote)"
                                    :class="{
                                        'border-bottom':
                                            i < filteredQuotes.length - 1,
                                    }"
                                >
                                    <v-list-item-content>
                                        <v-list-item-subtitle class="mb-2">{{
                                            quote.updatedOn
                                        }}</v-list-item-subtitle>
                                        <v-list-item-title class="mb-2">{{
                                            `(${quote.number}) ${quote.name}`
                                        }}</v-list-item-title>
                                        <v-list-item-subtitle>
                                            <v-chip
                                                dark
                                                :color="getColor(quote.status)"
                                            >
                                                {{ getStatus(quote.status) }}
                                            </v-chip>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-lazy>
                        </v-sheet>
                    </div>
                </template>
                <v-list-item v-else>
                    <v-list-item-content>
                        <v-list-item-title class="text-center">
                            No quotes
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-col>
        <v-col
            ref="content"
            cols="12"
            sm="0"
            md="9"
            lg="9"
            :style="`height: ${height}px`"
        >
            <router-view @show="show" />
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import API from '@/services/api'
import { storage } from '@/services/firebase'
import moment from 'moment'
import _ from 'lodash'

export default {
    name: 'History',
    data() {
        return {
            loading: false,
            height: 0,
            search: null,
            quote: null,
            loadingError: false,
            errorMsg: null,
            companyId: JSON.parse(localStorage.getItem('company')),
            folderClientsPictures: 'clients_pictures',
            folderUsersPictures: 'users_pictures',
            folderDocCommercial: 'commercial_documents',
            folderDocTechnical: 'technical_documents',
            folderLogo: 'company_logo',
            folderImages: 'photo_galery',
            createDialog: false,
            states: [
                {
                    text: 'All',
                    value: 'ALL',
                },
                {
                    text: 'Bidding',
                    value: 'OPEN',
                },
                {
                    text: 'Follow Up',
                    value: 'SENT',
                },
                {
                    text: 'Awarded',
                    value: 'AWARDED',
                },
                {
                    text: 'Approval',
                    value: 'APPROVAL',
                },
                {
                    text: 'Lost',
                    value: 'LOST',
                },
            ],
            state: 'ALL',
            sortBy: 'updatedOn',
            desc: true,
            desc2: true,
            quoteByOpen: undefined,
            users: [],
        }
    },
    computed: {
        ...mapGetters([
            'getClientQuote',
            'getSelectedQuoteInGeneral',
            'getNotificationResource',
        ]),
        filteredQuotes() {
            return this.historyQuotes.filter(q => {
                if (this.search && this.state === 'ALL') {
                    return (
                        q.name
                            .toLowerCase()
                            .includes(this.search.toLowerCase()) ||
                        `${q.number}`
                            .toLowerCase()
                            .includes(this.search.toLowerCase())
                    )
                } else if (this.search && this.state !== 'ALL') {
                    return (
                        (q.name
                            .toLowerCase()
                            .includes(this.search.toLowerCase()) ||
                            `${q.number}`
                                .toLowerCase()
                                .includes(this.search.toLowerCase())) &&
                        q.status === this.state
                    )
                } else if (!this.search && this.state !== 'ALL') {
                    return q.status === this.state
                } else {
                    return true
                }
            })
        },
        ...mapState([
            'historyQuotes',
            'clients',
            'contacts',
            'settings',
            'userRef',
        ]),
    },
    watch: {
        getSelectedQuoteInGeneral() {
            const quote = this.historyQuotes.find(
                q => q.id === this.getSelectedQuoteInGeneral?.id
            )
            if (quote) {
                this.hide(quote)
                this.setSelectedQuoteInGeneral(undefined)
            }
        },
        historyQuotes: function(newQuotes) {
            const params = this.$router.currentRoute.params
            if (params.id) {
                if (!params.quote) {
                    const quote = newQuotes.find(i => i.id == params.id)
                    this.$router.currentRoute.params.quote = quote
                }
            }
            if (
                this.getSelectedQuoteInGeneral ||
                this.getNotificationResource ||
                this.$route.query.resourceId
            ) {
                const quote = this.historyQuotes.find(
                    q =>
                        q.id ===
                        (this.getNotificationResource ||
                            this.$route.query.resourceId.trim() ||
                            this.getSelectedQuoteInGeneral?.id)
                )
                if (quote) {
                    this.hide(quote)
                    this.setSelectedQuoteInGeneral(undefined)
                    this.setNotificationResource(undefined)
                }
            }
        },
        quoteByOpen() {
            if (this.quoteByOpen) {
                this.$router.push({
                    name:
                        this.quoteByOpen.status !== 'SENT'
                            ? 'historyGeneralInfo'
                            : 'historyFollowUp',
                    params: {
                        id: this.quoteByOpen.id,
                        quote: _.cloneDeep(this.quoteByOpen),
                        history: true,
                        settings: this.settings,
                    },
                })
            }
            this.quoteByOpen = undefined
        },
    },
    methods: {
        ...mapMutations([
            'setSelectedQuoteInGeneral',
            'setErrorItems',
            'setNotificationResource',
        ]),
        getStatus(status) {
            if (status == 'OPEN') return 'Bidding'
            else if (status == 'AWARDED') return 'Awarded'
            else if (status == 'LOST') return 'Lost'
            else if (status == 'SENT') return 'Follow Up'
            else return 'Approval'
        },
        getColor(status) {
            if (status == 'OPEN') return 'primary'
            else if (status == 'AWARDED') return 'success'
            else if (status == 'LOST') return 'error'
            else if (status == 'SENT') return 'warning'
            else return 'orange'
        },
        onResize() {
            this.height =
                window.innerHeight -
                this.$vuetify.application.top -
                this.$vuetify.application.footer
        },
        hide(quote) {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.$refs.menu.classList.remove('d-flex')
                this.$refs.menu.classList.add('d-none')
            }
            this.$router.push({
                name:
                    quote.status !== 'SENT'
                        ? 'historyGeneralInfo'
                        : 'historyFollowUp',
                params: {
                    id: quote.id,
                    quote,
                    history: true,
                    settings: this.settings,
                },
            })
        },
        show() {
            this.$refs.menu.classList.remove('d-none')
            this.$refs.menu.classList.add('d-flex')
        },
        ...mapActions([
            'addHistoryQuotesAction',
            'addUsersAction',
            'addSettingsAction',
        ]),
        getUsers: async function() {
            try {
                this.loadingError = false
                this.errorMsg = null
                this.loading = true
                const {
                    data: { users },
                } = await API.getUsers(this.companyId)
                for (const user of users) {
                    user.createdOn = moment
                        .unix(user.createdOn._seconds)
                        .format('MMM/DD/YYYY HH:mm')
                }
                this.users = users
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        getQuotes: async function() {
            try {
                this.loadingError = false
                this.errorMsg = null
                this.loading = true
                const {
                    data: { quotesHistory },
                } = await API.getHistoryQuotes()
                for (const quote of quotesHistory) {
                    if (!quote.number) {
                        quote.shortId = quote.createdOn._seconds
                            .toString(16)
                            .toUpperCase()
                    }

                    if (quote.updatedOn) {
                        quote.seconds = quote.updatedOn._seconds
                        quote.updatedOn = moment
                            .unix(quote.updatedOn._seconds)
                            .format('MMM/DD/YYYY h:mm a')
                    } else {
                        quote.seconds = quote.createdOn._seconds
                    }

                    quote.date = moment
                        .unix(quote.createdOn._seconds)
                        .format('MMM DD YYYY ')
                    quote.createdOn = moment
                        .unix(quote.createdOn._seconds)
                        .format('MMM/DD/YYYY h:mm a')

                    if (quote.collaborators) {
                        quote.dataCollaborators = []
                        quote.collaborators.forEach(element => {
                            const dataUser = this.users.find(
                                user => element == user.id
                            )
                            quote.dataCollaborators.push(dataUser)
                        })
                    }
                    if (quote.userId) {
                        quote.mainContact = this.users.find(
                            user => quote.userId == user.id
                        )
                    }
                    if (quote.id === this.getClientQuote) {
                        this.quoteByOpen = _.cloneDeep(quote)
                    }
                }
                quotesHistory.sort((a, b) => b.seconds - a.seconds)
                this.addHistoryQuotesAction(quotesHistory)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async getSettings() {
            try {
                this.loading = true
                this.loadingError = false
                this.errorMsg = null
                const {
                    data: { settings },
                } = await API.getSettings()
                const indexLogo = settings.findIndex(x => x.name == 'Company')
                if (settings[indexLogo].generalInfo.image) {
                    const path = `${this.companyId}/${this.folderLogo}`
                    const storageRef = storage().ref(path)
                    const profilePicRef = storageRef.child(
                        settings[indexLogo].generalInfo.image.file
                    )
                    settings[
                        indexLogo
                    ].generalInfo.image.imageURL = await profilePicRef.getDownloadURL()
                }
                let orderSettings = settings.filter(x => x.name == 'Company')
                orderSettings[1] = settings.find(x => x.name == 'Exchange')
                orderSettings[2] = settings.find(x => x.name == 'Permissions')
                orderSettings[3] = settings.find(x => x.name == 'Quotes')

                const calculations = this.userRef.permissions.find(
                    x => x == 'calculations'
                )

                if (calculations) {
                    const item = settings.find(x => x.name == 'Calculations')
                    if (item) {
                        orderSettings[4] = item
                    }
                }
                const finish = settings.find(x => x.name == 'FinishAndUCCode')
                if (finish) {
                    orderSettings.push(finish)
                }
                const processes = settings.find(x => x.name == 'Processes')
                const products = settings.find(x => x.name == 'Products')
                const invoicing = settings.find(x => x.name == 'Invoicing')
                if (invoicing) {
                    orderSettings.push(invoicing)
                }

                if (processes) {
                    const machines = {
                        name: 'Machines',
                        nameRoute: 'machinesSettings',
                        id: processes.id,
                    }
                    orderSettings.push(machines)
                }

                const workOrderTab = {
                    name: 'WorkOrders',
                    nameRoute: 'workOrderSettings',
                    processes: processes || {},
                    products: products || {},
                }
                orderSettings.push(workOrderTab)
                const operatorsTab = {
                    name: 'Operators',
                    nameRoute: 'operators',
                }
                orderSettings.push(operatorsTab)
                this.addSettingsAction(orderSettings)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
    async created() {
        if (this.users.length == 0) {
            await this.getUsers()
        }
        if (this.settings.length == 0) {
            await this.getSettings()
        }
        await this.getQuotes()
    },
}
</script>

<style scoped>
.border-right {
    border-width: 0 1px 0 0;
    border-style: solid;
    border-color: #f0f0f0;
}
.border-bottom {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #f0f0f0;
}
</style>
